<template>
  <!-- 作品详情配置抽屉 -->
  <drawer-layout
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    permission="system:font:list:button:edit"
    @show="show"
    destroy-on-close
    @closed="closed"
    @save="save"
    @edit="edit"
  >
    <div slot="form">
      <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :loading="loading"
        :rowSelection="rowSelection"
        @change="handleTableChange"
      ></a-table>
    </div>
    <div></div>
  </drawer-layout>
</template>

<script lang="jsx">
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { postAction, getAction, putAction } from '@/api/manage'
const defaultSelectedDeparts = []
const defaultSelectedPositions = []
const defaultFormData = {
  textLayout: 1,
  styleItem: {
    textAlign: 'left',
    lineHeight: '1.2',
    scaleY: 1,
    scaleX: 1,
    fontSize: 8,
    fontColor: '#000000',
    antiAliasMethod: 'antiAliasSharp',
    // outlineColor: '#ffffff',
    // outlineWidth: 4,
  },
  typeId: 1,
  classId: 1,
  isSync: false,
}
export default {
  mixins: [DrawerMixins],
  data() {
    return {
      url: {
        list: '',
        edit: "/productDetail",
        add: "/productDetail",
      },
      loading:false,
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      selectedRowKeys: [],
      /* table选中records*/
      selectionRows: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          width: 80
        },
        {
          title: '标题',
          dataIndex: 'title',
          width: 170
        },
      ],
      tableData:[],
      selectedPositions: [],
      tooltipValue: "",
      disRowTitles:[],
      gridLayout: {
        type: 'flex',
        justify: 'end'
      }
    }
  },
  props:{
    domain:{
      type: String,
      default: () => 'PRODUCT_DETAIL',
    },
    type:{
      type:String,
      default:()=>"GLOBAL"
    },
    relationId:{
      type:String,
      default:()=>""
    },
    platformId:{
      type:String,
      default:()=>""
    },
    dataSource:{
      type:Array,
      default:()=>[]
    },
    departId:{
      type:String,
      default:''
    }
  },
  filters: {
    typeFilter(type) {
      const typeMap = {
        "DYNAMIC_FORM": "动态表单",
        "INPUT": "单行文本",
        "TEXTAREA": "多行文本",
        "RADIO": "单项选择",
        "SELECT": "下拉选择",
      }
      return typeMap[type];
    },
    canAddFilter(canAdd) {
      const canAddMap = {
        "true": "允许加行",
        "false": "不允许加行",
        "null": "",
      }
      return canAddMap[canAdd]
    }
  },
  created() {
  },
  computed:{
    rowSelection() {
      const _this = this
      const { selectedRowKeys,selectionRows,disRowTitles } = this
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys,selectionRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectionRows = selectionRows
        },
        getCheckboxProps: (record) => ({
          props: {
             disabled: this.disRowTitles.indexOf(record.title)!=-1
          },
        }),
      }
    },
  },
  methods: {
    getCheckboxProps(){
      const _this = this
      return {
        getCheckboxProps: (record) => ({
          disabled:true,
        })
      }
    },
    async save(){
      const res = await postAction('/productDetail/copy/up', {
            type: this.type,
            relationId: this.relationId,
            ids:this.selectedRowKeys,
            domain:this.domain
      })
      if (res.success) {
         this.$message.success(res.message||res.msg?"保存成功":"")
         this.isSaved = true
         this.closed()
       } else {
         this.$message.error(res.message||res.msg)
       }
    },
    closed() {
       const self = this;
        this.selectedRowKeys=[];
        this.selectionRows=[];
        this.disRowTitles=[]
        this.pagination={
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: "0"
      };
      if (this.isSaved || !this.isEdit) {
        if (this.formData.typeId == 0 && this.isForm && !this.isSaved && typeof this.oldFormData == 'object') {
          this.inspectFormData()
        } else {
          self.exit()
        }
      } else {
        this.inspectFormData()
      }
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      this.pagination={...pagination}
      this.getTabelData()
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      // console.log(selectedRowKeys,selectionRows,"onSelectChange")
      this.selectedRowKeys = selectedRowKeys
      this.selectionRows = selectionRows
      console.log(selectedRowKeys)
    },
    async getTabelData(){
      const params = {
        current:this.pagination.current,
        size:this.pagination.pageSize,
        relationId:this.platformId,
        type:this.type == "PRODUCT"?"PLATFORM":"GLOBAL",
        domain:this.domain
      }
      if (this.type == "PLATFORM") {
        params.departId=this.departId
        delete params.relationId;
      }
      const res = await getAction('/productDetail/page',params)
      if(res.success){
        this.tableData = res.data.records
        this.disRowTitles=[]
        this.dataSource.forEach(item=>{
          this.disRowTitles.push(item.title)
        })
        if (res.data.total) {
            this.pagination.total = res.data.total - 0;
        } else {
            this.pagination.total = 0
        }
      }
    },
    initForm() {
      this.formData = {
        ...defaultFormData,
      }
    },
    setFormData() {
/*       this.formData.type=this.type;
      this.formData.relationId=this.relationId; */
    },
    show() {
      console.log('显示...', { 'this.curRow': this.curRow })
      this.selectedRowKeys=[];
      this.selectionRows=[];
      this.confirmBtnDisable = true
      this.getTabelData()
    },
    startEdit() {
    }
  },
}
</script>

<style scoped lang="less">
/deep/ .description {
  .ant-form-item-control-wrapper {
    padding-right: 66px;
  }
}
/deep/ .option-item {
  .ant-form-item-control-wrapper {
    width: 66%;
    .ant-form-item-control {
      width: 100%;
      .ant-form-item-children {
        width: 100%;
      }
    }
  }
}
/deep/ .ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: rgba(0, 0, 0, 0.65);
  overflow: unset;
}

/deep/ .ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 4px;
}

/deep/ .first-form-item {
  label {
    font-weight: 400;
  }
}

/deep/ .tag-div .ant-tag {
  width: 100%;
  white-space: pre-wrap;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/deep/ .edit-form-remove .ant-form-item-children {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card-remove {
  display: flex;
  align-items: center;
  width: 58px;
  justify-content: end;
}

.add-item-form-item {
  width: 120px;

  /deep/ .ant-col {
    width: inherit;
  }
}

.ant-form > .ant-form-item:first-child {
  /deep/ .ant-form-item-label {
    font-weight: 900;
  }
}

.edit-form > .ant-form-item:first-child {
  /deep/ .ant-form-item-label {
    font-weight: 900;
  }
}

/deep/ .ant-form-item {
  max-width: 100%;
}

/deep/ .ant-form-item-label {
  width: 120px;
  margin-right: 16px;
}

/deep/ .ant-row {
  display: flex;
}

.dynamic-card-prop-item {
  width: 100%;
}

/deep/ .ant-form-item-children {
  .ant-row-flex {
    & > .ant-col {
      & > * {
        margin-left: 10px;
      }

      &:first-child > * {
        margin-left: 0px;
      }
    }
  }
}
</style>
