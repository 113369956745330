var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: "system:font:list:button:edit",
        "destroy-on-close": "",
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c("a-table", {
            ref: "table",
            attrs: {
              size: "middle",
              rowKey: "id",
              columns: _vm.columns,
              "data-source": _vm.tableData,
              pagination: _vm.pagination,
              loading: _vm.loading,
              rowSelection: _vm.rowSelection,
            },
            on: { change: _vm.handleTableChange },
          }),
        ],
        1
      ),
      _c("div"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }